import React, { useEffect,useRef } from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import Index from "./components/Index/Index.js";
import PersonPage from "./components/Person/PersonPage";
import About from "./components/About/About"
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path = "/about" exact component = {About}/>
        <Route path="/person/:slug" exact component={PersonPage} />
        <Route path="/" render={() => <div>404</div>} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
