import React, { Component } from "react";
import {
  Button,
  Comment,
  Icon,

  Image, Modal
} from "semantic-ui-react";
import CommentForm from "./CommentForm";
import { getCommentData, PersonContext, postCommentVote } from "./PersonContext";

class MyComment extends Component {
  static contextType = PersonContext;
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      isLikeButtonSelected: false,
      Votes: this.props.c.Votes,
      modalOpen: false
    };
    this.updateComment = this.updateComment.bind(this);
    this.onLike = this.onLike.bind(this);
    
  }
  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })
  componentDidMount() {
    this.updateComment();
  }
  async updateComment() {
    const data = await getCommentData(this.props.c.Slug);

    this.setState({
      comments: data,
    });
  }
  onLike() {
    if (this.state.isLikeButtonSelected == false) {
      this.setState((state, props) => {
        return { Votes: state.Votes + 1, isLikeButtonSelected: true };
      });
      // fetch with true
      postCommentVote(this.props.c.Slug, "true");
    } else {
      this.setState((state, props) => {
        return { Votes: state.Votes - 1, isLikeButtonSelected: false };
      });
      postCommentVote(this.props.c.Slug, "false");
    }
  }
  
  // pass the c index of the context Comments Array then acsess the context instead of using props
  // because the props is delting the damm comments
  render() {
    return (
      <Comment key={this.props.c.Slug}>
        <Comment.Content>
        
          <Comment.Author as="a">{this.props.c.AuthorName ? this.props.c.AuthorName : 'Anonymous'}</Comment.Author>
          {/* <Comment.Metadata>
            <span>{this.props.c.TimeString}</span>
          </Comment.Metadata> */}
          <Images Images={this.props.c.Images} />
          <Comment.Text>{this.props.c.Content}</Comment.Text>
          <Comment.Actions>
            {this.state.isLikeButtonSelected ? (
              <Button size="mini" color="red" onClick={this.onLike}>
                <Icon name="heart" />
                {this.state.Votes}
              </Button>
            ) : (
              <Button size="mini" onClick={this.onLike}>
                <Icon name="heart" />
                {this.state.Votes}
              </Button>
            )}

            <div style={{ display: "flex" }}>
    
              <Modal
                trigger={<a onClick={this.handleOpen}>Reply</a>}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                
                size="small"
              >
                <Modal.Content>
                <CommentForm
                    url={this.props.c.Slug}
                    callbackFromParent={this.updateComment}
                    closeModal = {this.handleClose}
                  />
                </Modal.Content>
             
              </Modal>
            </div>
          </Comment.Actions>
        </Comment.Content>
        {this.state.comments != null && (
          <Comment.Group>
            {this.state.comments.map((c) => (
              <MyComment c={c} key={c.Slug} />
            ))}
          </Comment.Group>
        )}
      </Comment>
    );
  }
}

class Images extends Component {
  render() {
    if (this.props.Images != undefined) {
      return (
        <div>
          {this.props.Images.map((image) => (
            <Image src={image} key = {image}></Image>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default MyComment;
