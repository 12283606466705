import React, { useState, useRef, useEffect } from "react";
import { Menu } from "semantic-ui-react";

function NumberOfUsers(props) {
  const [timeoutId, setTimeoutId] = useState();
  const firstUpdate = useRef(true);
  window.onbeforeunload = exit;
  window.onload = () =>{
    console.log("onload")
    fetch("/addUser")
  }
  function exit() {
      console.log("thing")
      navigator.sendBeacon("/exit", "123345")
  }
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      getNumOfUsers()
      
      return;
    }
    let mounted = true;
    window.addEventListener("focus", handleVisibilityChange);
    window.addEventListener("blur", handleVisibilityChange);

    function handleVisibilityChange(focus) {
      if (mounted) {
        console.log(focus.type);


        if (focus.type === "focus") {
          if (timeoutId != undefined) {
            clearInterval(timeoutId)
            getNumOfUsers()
          }
         
        } else if (focus.type === "blur") {
          clearTimeout(timeoutId);
          console.log("clear getinterval");
        }
      } else {
        console.log("IN ELSE");
        clearTimeout(timeoutId);
      }
    }
    return () => {
      window.removeEventListener("focus", handleVisibilityChange);
      window.removeEventListener("blur", handleVisibilityChange);
      clearTimeout(timeoutId);
      mounted = false;
    };
  });


  const [numUsers, setNumUsers] = useState();

  async function getNumOfUsers() {
    try {
      const response = await fetch("/numberofusers");
      const data = await response.json();
      console.log(data)
      setNumUsers(data);
    } catch (error) {}
    setTimeoutId(setTimeout(getNumOfUsers, 5000));
  }
  return (
    <Menu.Item name="numUsers" position="left" className="numOfUsers">
      Number of Users: {numUsers}
    </Menu.Item>
  );
}

export default NumberOfUsers;


