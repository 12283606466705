import React, { Component } from "react";
import { Button, Form } from "semantic-ui-react";
import { PersonContext } from "./PersonContext";
import { Popup } from "semantic-ui-react";
import GifDisplayGrid from "./GifDisplayGrid";
import GiphyPicker from "./GiphyPicker";
import "./CommentForm.css"
var linkify = require("linkifyjs");

class CommentForm extends Component {
  static contextType = PersonContext;

  constructor(props) {
    super(props);
    this.state = {
      content: "",
      files: null,
      images: [],
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGifAdd = this.handleGifAdd.bind(this);
  }
  handleFormSubmit(e) {
    e.preventDefault();
    this.context.setisUpdating(true);

    var data = new FormData();
    data.append("content", this.state.content);
    data.append("images", this.state.images);

    fetch("/comment" + this.props.url, {
      method: "POST",
      body: data,
    }).then(() => {
      if (this.props.callbackFromParent != null) {
        this.props.callbackFromParent();
        this.context.getPData();
      } else {
        this.context.getPData();
      }
      this.setState({
        files: null,
        content: "",
        images: [],
      });
      if (this.props.closeModal !== undefined) {
        this.props.closeModal();
      }
    });
  }

  handleChange(event) {
    if (event.target.name === "content") {
      var content = event.target.value;
      var links = linkify.find(content);
      if (links.length > 0) {
        console.log(links);
        links.forEach((link) => {
          content = content.replace(link.href, "");
        });

        this.setState(function (state, props) {
          var myImages = state.images;
          myImages[0] = links[0].href;
          return {
            images: myImages,
          };
        });
      }

      this.setState({
        [event.target.name]: content,
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }
  handleGifAdd(gif) {
    var downsizedUrl = gif.images.downsized.url;
  
    this.setState(function (state, props) {
      var myImages = state.images;
      myImages[0] = downsizedUrl;
      return {
        images: myImages,
      };
    });
  }
  render() {
    let isUpdating = this.context.isUpdating;
    const SubmitButton = () => {
      if (isUpdating) {
        return (
          <Button
            content="Add Reply"
            labelPosition="left"
            icon="edit"
            disabled
          />
        );
      } else {
        return (
          <Button
            content="Add Reply"
            labelPosition="left"
            icon="edit"
            primary
          />
        );
      }
    };
    return (
      <Form
        className="CommentForm"
        reply
        onSubmit={this.handleFormSubmit}
        onChange={this.handleChange}
        method="POST"
        encType="multipart/form-data"
        action={"/comment" + this.props.url}
      >
        <Form.TextArea
          placeholder="Write anything you want. You can paste Image URLs from other websites here too..."
          name="content"
          value={this.state.content}
        ></Form.TextArea>
        <GifDisplayGrid images={this.state.images} />

        <br></br>
        <SubmitButton />
        <Popup
          trigger={
            <Button
              onClick={(b) => {
                b.preventDefault();
              }}
            >
              GIFs
            </Button>
          }
          on="click"
          pinned
        >
          <Popup.Content>
            <GiphyPicker handleGifAdd={this.handleGifAdd} type="gifs" />
          </Popup.Content>
        </Popup>
        <Popup
          trigger={
            <Button
              onClick={(b) => {
                b.preventDefault();
              }}
            >
              Stickers
            </Button>
          }
          on="click"
          pinned
        >
          <Popup.Content>
            <GiphyPicker handleGifAdd={this.handleGifAdd} type="stickers" />
          </Popup.Content>
        </Popup>

        {/* <GiphyPicker handleGifAdd={this.handleGifAdd} /> */}
        {/* <Input name="imageFile" type="file" multiple></Input> */}
      </Form>
    );
  }
}

export default CommentForm;
