import React from "react";
import {Icon} from "semantic-ui-react"

function IconPageLink(props) {
  return (
    <a style={{ color: "inherit" }} href={props.href}>
      <Icon name= {props.name} link />
    </a>
  );
}
export default IconPageLink