import React, { createContext, useState, useEffect, useRef } from "react";

export const HomeContext = createContext(null);
var persons = [];
const FetchFunctions = require("./FetchFunctions");
const maxLength = 100;

export const HomeContextProvider = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [Persons, setPersons] = useState([]);

  const [searchType, setSearchType] = useState("all");
  const [order, setOrder] = useState(-1);
  const prevOrderRef = useRef(order);
  // const [count, setCount] = useState(10);
  const [isSearching, setIsSearching] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [isFetching, setIsFeteching] = useState(false);

  useEffect(() => {
    prevOrderRef.current = order;
  });
  const prevOrder = prevOrderRef.current;
  useEffect(() => {
    if (Persons != null) {
    }
  }, [Persons]);
  useEffect(() => {
    if (!isFetching) return;
    if (persons.length > maxLength) {
      return;
    }
    GetPersons(-1, false);
  }, [isFetching]);
  useEffect(() => {
    clearTimeout(typingTimeout);
    if (prevOrder != order) {
      console.log(prevOrder);
      console.log(order);
      persons = [];
    }
    if (searchQuery !== "" || searchType !== "all") {
      GetPersons(-1, true);
      // console.log("GET PERSONS FROM SEARCH COMPONET");
    } else {
      // console.log("GET PERSONS");
      setIsSearching(false);

      GetPersons(-1, false);
    }
  }, [searchQuery, searchType, order]);

  var isRunning = false;

  async function GetPersons(order, search) {
    console.log(isRunning);
    if (!isRunning) {
      if (search) {
        // setIsSearching(true)
        await Search(false, false);
        // setIsSearching(false)
      } else if (!search) {
        await Data();
        let hasVScroll =
          document.body.scrollHeight > document.body.clientHeight;
        console.log("hasVsCroll" + hasVScroll);

        if (!hasVScroll) {
          console.log("Running Data for hasVSCroll")
          setIsFeteching(true)
        } 
        setIsFeteching(false);
        
      }
    } else {
      // console.log("IsRunning: " + isRunning);
    }
  }

  async function Data() {
    if (persons.length > maxLength && Persons.length > maxLength) {
      return;
    } else if (
      persons.length > maxLength &&
      Persons.length !== persons.length
    ) {
      console.log("The Exception case");
      setPersons(persons);
      return;
    }
    let getPersons = await FetchFunctions.GetPersonsData(order, persons); // IT's adding the extra person at the 10 mark somewhere in here

    if (getPersons != null) {
      getPersons.forEach((element) => {
        if (FetchFunctions.slugExists(element.Slug, persons) === false) {
          persons.push(element);
        } else {
          console.log("DUPLCIATE");
        }
      });
    }

    console.log("persons: " + persons);

    setPersons(persons);
  }
  var filteredPersons = [];
  async function Search(runSearch, runFilter) {
    setIsSearching(true);

    // console.log("SEARCHED");
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(async () => {
        console.log("Would have done search now");

        // if (runSearch === false) {
        //   Search(true, false);
        // }
        if (runFilter === false) {
          setIsSearching(true);
          console.log("SET SERACHING TRUE");
          await Search(true, true);
          console.log("SET SERACHING False");

          setIsSearching(false);
        }
      }, 1000)
    );
    if (runFilter === true) {
      console.log("filtered persons");
      filteredPersons = persons.filter((person) => {
        return (
          person.Name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
    }

    if (runSearch === true) {
      console.log("run search persons");
      console.log(searchQuery);
      // let mysearchq = searchQuery

      let addPersons = await FetchFunctions.RemoteSearch(
        order,
        searchQuery,
        searchType,
        filteredPersons
      );
      if (addPersons != null && addPersons.length > 0) {
        filteredPersons.push(...addPersons);
      }
    }

    if (filteredPersons.length > 0) {
      setPersons(filteredPersons);
    } else {
    }
  }

  return (
    <HomeContext.Provider
      value={{
        searchQuery,
        searchType,
        setSearchQuery,
        Persons,
        setSearchType,
        setOrder,
        order,
        GetPersons,
        setPersons,
        isSearching,
        setIsFeteching,
      }}
    >
      {props.children}
    </HomeContext.Provider>
  );
};
