import React, { Component, useEffect, useRef } from "react";
import IndexContainer from "./IndexContainter";
import { HomeContextProvider } from "./HomeContext";
import MyMenu from "../MyMenu/MyMenu";
import Title from './Title'
import "./Index.css"
function Index() {
  var divRef = useRef()
  useEffect(() => {
    var scale = Math.min()
    var myDiv = divRef.current
    // var elHeight = myDiv.outerHeight()
    // var elWidth = myDiv.outerHeight()
  // console.log(elHeight + "x " + elWidth)
  console.log(myDiv)
 })
  return (
    <div style = {{overflow: "hidden"}} className = "scaleWrapper"  ref = {divRef}>
      <MyMenu></MyMenu>
      <Title />

      <HomeContextProvider>
        <IndexContainer></IndexContainer>
      </HomeContextProvider>
    </div>
  );
}

export default Index;
