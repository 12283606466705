export async function searchPersons(order, searchQuery, searchType) {
  let searchQCorrect = JSON.parse(JSON.stringify(searchQuery));
  searchQCorrect =
    searchQuery.replace(/\u0027|\u2019|\u0060|\u2018/, `'`); 
 
  const endpoint =
    "/persons?name=" +
    searchQCorrect +
    "&type=" +
    searchType +
    "&order=" +
    order;
  console.log(endpoint);

  const response = await fetch(endpoint);
  const data = await response.json();

  return data;
}

const personsDataurl = "/json";

export async function GetPersonsData(order, persons) {
  console.log("This is the skip length" + persons.length);
  let url =
    personsDataurl +
    "?order=" +
    order +
    "&number=" +
    10 +
    // (count - Persons.length) +
    "&skip=" +
    persons.length;
  const response = await fetch(url);
  const data = await response.json();
  return data;
}
export function slugExists(Slug, p) {
  return p.some(function (el) {
    return el.Slug === Slug;
  });
}
const FetchFunctions = require("./FetchFunctions");

export async function RemoteSearch(
  order,
  searchQuery,
  searchType,
  filteredPersons
) {
  let addPersons = [];
  console.log("Doing Remote Search");

  let searchPersons = await FetchFunctions.searchPersons(
    order,
    searchQuery,
    searchType
  );

  if (filteredPersons == null || filteredPersons.length < 1) {
    addPersons = searchPersons;
    return addPersons;
  } else if (searchPersons != null && searchPersons.length > 0) {
    searchPersons.forEach((element) => {
      if (slugExists(element.Slug, filteredPersons) === false) {
        addPersons.push(element);
      } else {
        console.log("DUPLCIATE");
      }
    });
    return addPersons;
  }
}
