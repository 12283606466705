import React from "react";

import { Grid, Image } from "semantic-ui-react";

function GifDisplayGrid(props) {
    return(
        <Grid columns="equal" >
          <Grid.Row >
            {props.images.map((image) => (
              <Grid.Column key = {image} >
                <Image size = "medium" src={image} key = {image} />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
    )
}

export default GifDisplayGrid