import React, {Component} from 'react'
import { render } from 'react-dom'
import ReactGiphySearchbox from 'react-giphy-searchbox'

class GiphyPicker extends Component {
    constructor(props) {
        super(props);
        
    
        
      }
    

    render(){
        var stickersType = false
        if(this.props.type == "stickers"){
            stickersType = true
        }
      return(
          <div>
 <ReactGiphySearchbox
    apiKey="O1f8RpemLoGqKr07AuifpHEb2TeeVmV9" 
    onSelect={item => this.props.handleGifAdd(item)}
    library = {this.props.type}
    rating = "R"
    searchPlaceholder = {stickersType ? 'Search for Stickers' : "Search  for GIFs"}
    masonryConfig={[
        { columns: 2, imageWidth: 110, gutter: 5 },
        { mq: '700px', columns: 2, imageWidth: 110*2, gutter: 5 },
      ]}
    />
          </div>
       
      )
    }
    
  }

  export default GiphyPicker