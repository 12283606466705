import React, {useState, useContext, useEffect} from "react";
import {PersonContextProvider} from "./PersonContext";
import { PersonContext } from "./PersonContext";

import PersonContainer from "./PersonContainer";

function PersonPage(props) {

  return (
    <PersonContextProvider slug = {props.match.params.slug}>
      <PersonContainer ></PersonContainer>
    </PersonContextProvider>
  );
}

export default PersonPage;
