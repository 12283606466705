import React, { Component, useState, useEffect, useContext } from "react";
import Title from "../Index/Title";
import { Header, Grid } from "semantic-ui-react";
import "./PersonContainer.css";
import CommentGroup from "./CommentGroup";
import CommentForm from "./CommentForm";
import MyMenu from '../MyMenu/MyMenu'
// import PersonContextProvider from './PersonContext'
import { PersonContext } from "./PersonContext";
function PersonContainer(props) {
  const { slug, getPData, name, votes, staffJob } = useContext(PersonContext);

  useEffect(() => {
    console.log("useeffcet");
    // setSlug(props.slug)
    getPData();
  }, []);

  return (
    <div>
      {/* <Title></Title> */}
      <MyMenu></MyMenu>
      

      <Grid columns={2} padded textAlign="center">
        <Grid.Row>
          <Grid.Column>
            <h2>
              {name}, {staffJob}
            </h2>
          </Grid.Column>
          <Grid.Column>
            <h2>Likes: {votes}</h2>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Header as="h3"  style = {{textAlign: "center"}}>
        Comments
      </Header>
      <CommentForm url={"/" + slug} callbackFromParent={getPData} />
      {/* <FBComponetTest/> */}
      <CommentGroup />
    </div>
  );
}

export default PersonContainer;

