import React, { useEffect, useContext } from "react";

import PersonsTable from "./PersonsTable";
import SearchInputs from "./SearchInputs";
import { HomeContext } from "./HomeContext";

function IndexContainer(params) {
  const context = useContext(HomeContext);
 
  return (
    <div>
      
      <SearchInputs />
      <PersonsTable/>
    
    </div>
  );
}


export default IndexContainer;
