import React, { Component, useContext, useState } from "react";
// import CommentsBlock from 'simple-react-comments';
// import { commentsData } from './data/index';
import { Button, Comment, Form, Header, Image, Popup } from "semantic-ui-react";
import "./comment.css";
import CommentForm from "./CommentForm";
import MyComment from './MyComment'
import {PersonContext} from './PersonContext'
var url;

function CommentGroup(props) {

  const { comments} = useContext(PersonContext)
  
  if(comments != null){
    return(
      <div style = {{paddingBottom: "100px", paddingTop: "15px"}}>
         
      <Comment.Group>
        
        {comments.map((c, index) => (
       
          <MyComment c= {c} index =  {index} key = {c.Slug}/>
         
          
        ))}
      </Comment.Group>
    </div>
    )
  } else {
    return(<div></div>)
  }
  
}



export default CommentGroup;



