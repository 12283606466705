import React, { useContext, useState, useRef, useEffect } from "react";
import MyMenu from "../MyMenu/MyMenu";
import "./About.css";
import CommentGroup from "../Person/CommentGroup";
import { PersonContextProvider, PersonContext } from "../Person/PersonContext";
import IconPageLink from "./IconPageLink";
import CommentForm from "../Person/CommentForm"
function About(props) {
  return (
    <div className="noScrollBar">
      <MyMenu />
      <h3 style={{ textAlign: "center" }}>Why Was This Site Created?</h3>
      <div className="Margin-Paragraph" style={{ textAlign: "center" }}>
        <p>
          I wanted to give Cape Students an objective measurement of Cape
          faculty. End of year surveys are hardly anonymous or informative,
          given that these surveys never get released to us.
        </p>
        <p>
          This site serves as an open forum, feel free to say anything about
          anybody. TLS/SSL encryption
          ensures that no comments or votes can ever be traced back to the
          author.
        </p>
        <p>
          Most importantly, this site serves to provide a fun place for sharing
          comments, gifs, memes, etc. HAVE FUN!
        </p>
      </div>

      <p style={{ textAlign: "center" }}>&nbsp;</p>
      <p style={{ textAlign: "center" }}>
        Created by J.G &nbsp;
        <IconPageLink name="github" href="https://github.com/ggman12" />
        <IconPageLink
          name="instagram"
          href="https://www.instagram.com/goode.jonah/"
        />
        <IconPageLink name="twitter" href="https://twitter.com/acepro13" />
      </p>
      <p style={{ textAlign: "center" }}>
        Tech Stack: Golang, MongoDB, ReactJS, Google Cloud App Engine, Cloudflare
      </p>
      <p>&nbsp;</p>
      <h3 style={{ textAlign: "center" }}>
        Report bugs, questions, etc. below
      </h3>
      <p>&nbsp;</p>
      <PersonContextProvider slug={"/about"} page={"about"}>
        <Start></Start>
        <CommentForm url={"/about"}/>
        <CommentGroup />
      </PersonContextProvider>
    </div>
  );
}

function Start(props) {
  const { getPData } = useContext(PersonContext);

  useEffect(() => {
    console.log("getP Data");
    getPData();
  }, []);
  return <div></div>;
}

export default About;
