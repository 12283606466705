import React, { useContext, useEffect } from "react";
import { Loader, Table } from "semantic-ui-react";
import { HomeContext } from "./HomeContext";
import PersonRow from "./PersonRow";
import "./PersonsTable.css";
function PersonsTable(props) {
  const context = useContext(HomeContext);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  function handleScroll() {
    
    console.log();
    if (getScrollPercent() < 60) {
      return;
    }
    console.log("Fetch more list items!");
    context.setIsFeteching(true);
  }
  // useEffect(() => {
  //   function handleResize() {
  //     console.log(window.innerWidth + "x" + window.innerHeight)
  //   }
  //   window.addEventListener("resize", handleResize)
  // });
  if (context.Persons != null && context.isSearching === false) {
    return (
      <div>
        <style></style>
        <Table unstackable columns="2" className="scrollDiv"  >
          <Table.Body>
            {context.Persons.map((person, index) => (
              <PersonRow
                name={person.Name}
                slug={person.Slug}
                votes={person.Votes}
                key={person.Slug}
                index={index}
                person={person}
                commentCount = {person.CommentCount}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  } else {
    return (
      <div className="col-xs-1" align="center">
        {/* <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner> */}
        <Loader active inline="centered" />
      </div>
    );
  }

  // else {
  //   return (<div></div>)
  // }
}

function getScrollPercent() {
  var h = document.documentElement,
    b = document.body,
    st = "scrollTop",
    sh = "scrollHeight";
  return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
}
export default PersonsTable;
