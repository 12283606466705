import React, { createContext, Component, useState} from "react";

export const PersonContext = createContext(null);

export const PersonContextProvider = (props) => {
 

  const [name, setName] = useState("")
  const [votes, setVotes] = useState(0)
  const [slug, setSlug] = useState(props.slug)
  const [comments, setComments] = useState([])
  const [isUpdating, setisUpdating] = useState(false)
  const [staffJob, setStaffJob] = useState("")
  function getPData() {

    setisUpdating(true)
    // console.log("getPDate")
    if (props.slug === "/about") {
      getCommentData(props.slug).then((data) => {
        setComments(data)
      }).then(() => {
        setisUpdating(false)

      })
    } else {
      getPersonData(slug).then((data) => {
        // this.setState({
        //   name: data.Name,
        //   comments: data.Comments,
        //   slug: data.Slug,
        // });
        setName(data.Name)
        setComments(data.Comments)
        setSlug(data.Slug)
        setVotes(data.Votes)
        setStaffJob(data.StaffJob)
      }).then(()=>{
        setisUpdating(false)
      })
    }

  }
  return(
    <PersonContext.Provider value = {{name, slug, votes, comments, isUpdating, setisUpdating, setSlug, getPData, staffJob}}>
      {props.children}
    </PersonContext.Provider>
  )
}





async function getPersonData(slug) {
  const personDataurl = "/person/" + slug + "/json";
  console.log(personDataurl);
  const response = await fetch(personDataurl);

  const data = await response.json();
  data.Comments = await getCommentData("/" + slug)
  return data;
}

export async function getCommentData(slug) {
  slug = "/comment" + slug

  const data = await fetch(slug)
  const response = await data.json()
  return response
}

export async function postCommentVote(slug, yes) {
  slug = "/vote" + slug + "?addVote=" + yes
  await fetch(slug,{
    method: 'POST',
 
  })
}