import React, {Component} from "react";
import './Title.css'
// import "bootstrap/dist/css/bootstrap.min.css";
class Title extends Component {
  render() {
  
    return (

      <div className="myTitle">
        {/* <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css" /> */}

        <h1 className = "titleH1">Who is The Best at CHHS</h1>
        <p>Vote Then <b>Comment</b> Anonymously</p>
      </div>
    );
  }
}

export default Title