import React, { useState, useEffect, useRef } from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./MyMenu.css";
import NumberOfUsers from "./NumberOfUsers";
let hasRun = false;

function MyMenu(props) {

  return (
    <div>
  
      <Menu secondary>
        <Menu.Item
          position="left"
          name="Who is The Best at CHHS"
          // active={activeItem === 'editorials'}
        >
          <Link to="/">Home</Link>
        </Menu.Item>
        <NumberOfUsers />
        <Menu.Item name="About">
          <Link to="/about">About</Link>
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default MyMenu;
