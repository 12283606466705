import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import CommentIcon from "@material-ui/icons/Comment";

import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Nav } from "react-bootstrap";
import { Table, Grid } from "semantic-ui-react";
import { HomeContext } from "./HomeContext";
import "./PersonRow.css";
var myController = new AbortController();

function PersonRow(props) {
  var context = useContext(HomeContext);

  useState(() => {
    myController.abort();
  }, [context.setSearchQuery, context.setSearchType]);

  return (
    <Table.Row className="scrollRow">
      <Table.Cell width="4" className="TCell" style={{ fontSize: "1.285em" }}>
        {/* <Grid>
          <Grid.Column>
            
          </Grid.Column>
          <Grid.Row>
          
          </Grid.Row>
        </Grid> */}
        <a style = {{textDecoration: "underline"}} href={"/person/" + props.slug}>{props.name}</a>
      </Table.Cell>
      {/* <Table.Cell width = "3" className = "CCell">
      <CommentIconButton slug={props.slug} commentCount = {props.commentCount} />

      </Table.Cell> */}
      {/* <Table.Cell className = "MinPad">
      <CommentIconButton slug={props.slug} commentCount = {props.commentCount} />

      </Table.Cell> */}
      <Table.Cell textAlign="right" width="5" className="buttonCell">
        {/* <h3 style = {{fontSize: "200%"}}>APPLE</h3> */}
        <LikeDislikeButton
          style={{ fontSize: "100%" }}
          votes={props.votes}
          slug={props.slug}
          index={props.index}
          person={props.person}
        />
      </Table.Cell>
      <Table.Cell width="3" className="CCell">
        <CommentIconButton
          slug={props.slug}
          commentCount={props.commentCount}
        />
      </Table.Cell>
      {/* <Table.Cell width="2" className="buttonCell">
        <CommentIconButton slug={props.slug} commentCount = {props.commentCount} />
      </Table.Cell> */}
    </Table.Row>
  );
}

const LikeDislikeButton = (props) => {
  var context = useContext(HomeContext);
  var [isLiked, setLiked] = useState("none");
  var person = props.person;

  useEffect(() => {
    if (person.isLiked == undefined) {
      setLiked("none");
      person.isLiked = "none";
      person.beingCHANGEDbyRow = "YES IT WAS CHANGED";
    } else {
      setLiked(person.isLiked);
    }
  }, []);

  var [votes, setVotes] = useState(person.Votes);
  const prevVotesRef = useRef(votes);
  const [fetchVotes, setFetchVotes] = useState();

  const firstUpdate = useRef(true);
  useEffect(() => {
    prevVotesRef.current = votes;
  });
  const prevVotes = prevVotesRef.current;

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    let times = votes - prevVotes;
    let liked;
    if (times < 0) {
      liked = false;
    } else {
      liked = true;
    }
    const voteURL = "/person/" + props.slug + "/vote" + "?addVote=" + liked;
    console.log(voteURL);
    for (let i = 0; i < Math.abs(times); i++) {
      fetch(voteURL, {
        // THIS IS THE Problem for loop dosen't matter I don't think
        method: "POST",
        // signal: mySignal
      });
      // var oreq = new XMLHttpRequest()
      // oreq.open("POST", voteURL)
      // oreq.send()
    }
  }, [votes]);

  async function handleVoteClick(liked, times) {
    // the stuff breaks in here
    if (times === undefined || times == null) {
      times = 1;
    }

    if (liked === true) {
      setVotes(votes + times);
      person.Votes = person.Votes + times;
    } else {
      setVotes(votes - times);
      person.Votes = person.Votes - times;
    }

    if (liked === true) {
      setFetchVotes(true);
    } else if (liked === false) {
      setFetchVotes(false);
    }
  }

  useEffect(() => {
    var persons = context.Persons;
    persons[props.index].isLiked = isLiked;
  }, [isLiked]);
  function handleLikeClick() {
    if (isLiked === "none") {
      handleVoteClick(true);
      setLiked("true");
    } else if (isLiked === "false") {
      console.log("isLiked === false");
      handleVoteClick(true, 2);
      setLiked("true");
    } else {
      handleVoteClick(false);
      setLiked("none");
    }
  }
  function handleDisLikeClick() {
    if (isLiked === "none") {
      handleVoteClick(false);
      setLiked("false");
    } else if (isLiked === "true") {
      handleVoteClick(false, 2);
      setLiked("false");
    } else {
      handleVoteClick(true);
      setLiked("none");
    }
  }

  function DisLikeButton(params) {
    if (isLiked === "none" || isLiked === "true" || isLiked == undefined) {
      return (
        <IconButton edge="start" onClick={handleDisLikeClick} name="Dislike">
          <ArrowDropDownCircleIcon className="arrowIcon" />
        </IconButton>
      );
    } else if (isLiked == "false") {
      return (
        <IconButton edge="start" onClick={handleDisLikeClick} name="Dislike">
          <ArrowDropDownCircleIcon color="error" className="arrowIcon" />
        </IconButton>
      );
    }
  }
  function LikeButton(params) {
    if (isLiked === "none" || isLiked === "false" || isLiked === undefined) {
      return (
        <IconButton
          onClick={(e) => handleLikeClick(e)}
          edge="start"
          style={{ transform: [{ rotateY: "90deg" }] }}
          name="Like"
        >
          <ArrowDropDownCircleIcon
            className="arrowIcon"
            style={{ transform: "rotate(-180deg)" }}
          />
        </IconButton>
      );
    } else if (isLiked === "true") {
      return (
        <IconButton
          onClick={(e) => handleLikeClick(e)}
          edge="start"
          style={{ transform: [{ rotateY: "90deg" }] }}
          name="Like"
        >
          <ArrowDropDownCircleIcon
            color="primary"
            className="arrowIcon"
            style={{ transform: "rotate(-180deg)" }}
          />
        </IconButton>
      );
    }
  }
  return (
    <div>
      <DisLikeButton />

      <IconButton
        color="inherit"
        edge="start"
        style={{ textAlign: "center", alignContent: "center" }}
      >
        <div style={{ width: "26px" }}>{votes}</div>
      </IconButton>
      <LikeButton />
    </div>
  );
};

export default PersonRow;

function CommentIconButton(props) {
  return (
    <Nav.Item >
      <Nav.Link href={"/person/" + props.slug} style={{ fontSize: "100%" }}>
        <IconButton >
          <CommentIcon edge="start" style = {{fontSize: "1.3em"}} />
        </IconButton>
        <IconButton
          color="inherit"
          edge="start"
          style={{ textAlign: "center", alignContent: "center"}}
        >
          {props.commentCount}
        </IconButton>
      </Nav.Link>
    </Nav.Item>
  );
}
