import React, { useContext,useEffect,useState,useRef } from "react";
import { HomeContext } from "./HomeContext";
import { Input, Button, Divider } from "semantic-ui-react";
import "./SearchInputs.css";
function SearchInputs() {
  const context = useContext(HomeContext);
  // constructor() {
  //   super()
  //   this.handleChange = this.handleChange.bind(this);
  // }
  const firstUpdate = useRef(true);

  const [bigButton, setBigButton] = useState(false)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      handleResize()
    }
    function handleResize() {
      if (window.innerWidth > 700) {
        setBigButton(true)
      } else {
        setBigButton(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return()=> window.removeEventListener('resize', handleResize)
  })
  function handleChange(event) {
    const { name, value } = event.target;

    if (name == "searchQuery") {
      context.setSearchQuery(value);
    } else if (name == "searchType") {
      context.setSearchType(value);
    }
  }

  // function searchWithUpdatedInfo() {
  //   this.props.callbackFromParent(this.props.searchProps);
  // }

  function ToggleButtons(props) {
    function handleOrderChange(event) {
      const { value } = event.target;
      console.log(value);
      if (value === "Ascending") {
        context.setOrder(1);
      } else if (value === "Descending") {
        context.setOrder(-1);
      }
    }

    if (context.order === 1) {
      console.log("Here");
      return (
        <Button.Group name="searchType" size={bigButton ? "huge": 'medium'}>
          <div>
            <Button
              toggle
              active={false}
              onClick={handleOrderChange}
              value={"Descending"}
            >
              Most Liked
            </Button>
            <Button
              negative
              active={true}
              onClick={handleOrderChange}
              value={"Ascending"}
            >
              Most Disliked
            </Button>
          </div>
        </Button.Group>
      );
    }
    return (
      <Button.Group name="searchType" className="bgroup" size={bigButton ? "huge": 'medium'}>
        <div>
          <Button
            toggle
            active={true}
            onClick={handleOrderChange}
            value={"Descending"}
          >
            Most Liked
          </Button>
          <Button
            toggle
            active={false}
            onClick={handleOrderChange}
            value={"Ascending"}
          >
            Most Disliked
          </Button>
        </div>
      </Button.Group>
    );
  }
  return (
    <div align="center">
      <form onInput={handleChange}>
        <Input
          style={{ width: "1000px" }}
          className="form-control"
          name="searchQuery"
          placeholder="Search"
        />

        <Divider hidden className="tiny-divide" />
        {/* <div>
          <ToggleButtonGroup
            type="radio"
            name="searchType"
            defaultValue={"all"}
          >
            <ToggleButton variant="btn btn-secondary" value={"all"}>
              All
            </ToggleButton>
            <ToggleButton variant="btn btn-secondary" value={"staff"}>
              Staff
            </ToggleButton>
            <ToggleButton variant="btn btn-secondary" value={"student"}>
              Students
            </ToggleButton>
            <ToggleButton variant="btn btn-secondary" value={"9th"}>
              9th
            </ToggleButton>
            <ToggleButton variant="btn btn-secondary" value={"10th"}>
              10th
            </ToggleButton>
            <ToggleButton variant="btn btn-secondary" value={"11th"}>
              11th
            </ToggleButton>
            <ToggleButton variant="btn btn-secondary" value={"12th"}>
              12th
            </ToggleButton>
          </ToggleButtonGroup>
        </div> */}
      </form>
      <ToggleButtons />
    </div>
  );
}

export default SearchInputs;
